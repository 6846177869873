import React from 'react';
import { Modal, Image, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EventModal = ({ open, header, text, url, imageUrl, onCloseClick }) => {
  return (
    <Modal centered={false} open={open}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content image>
        <Image wrapped size="big" src={imageUrl} />
        <Modal.Description>
          <Header>
            <a href={url}>{header}</a>
          </Header>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onCloseClick} as={Link} to="/events/">
          Закрыть
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

EventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired
};

export default EventModal;
