import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import Afisha from './Afisha';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://846c30bf47dc4ff895bf08774b015036@sentry.pinsk.io/5'
});

ReactDOM.render(<Afisha />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
