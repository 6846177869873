import React, { memo, useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import EventAPI from '../api/Afisha';

const DataFormSelect = ({ label, placeholder, url, value, onChange }) => {
  const [state, setState] = useState({ loaded: false, data: [] });

  useEffect(() => {
    EventAPI.get(url).then(res => {
      const data = res.data.results.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name
      }));
      setState({ loaded: true, data });
    });
  }, [url]);

  return (
    <Form.Select
      label={label}
      placeholder={placeholder}
      fluid
      search
      selection
      value={value}
      options={state.data}
      onChange={onChange}
      disabled={!state.loaded}
      loading={!state.loaded}
    />
  );
};

DataFormSelect.defaultProps = {
  value: null
};

DataFormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default memo(DataFormSelect);
