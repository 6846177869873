import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Placeholder, Image, List } from 'semantic-ui-react';
import _ from 'underscore';
import EventAPI from '../api/Afisha';

const EventsPlaceholder = () =>
  _.times(15, i => (
    <Placeholder key={i}>
      <Placeholder.Header image>
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  ));

const EventsList = () => {
  const [state, setState] = useState({ loaded: false, events: [] });

  useEffect(() => {
    EventAPI.get('/events/').then(res => {
      setState({ loaded: true, events: res.data.results });
    });
  }, []);

  return (
    <List selection verticalAlign="middle">
      {state.loaded ? (
        state.events.map(event => (
          <List.Item key={event.id} as={Link} to={`/events/${event.id}/`}>
            <Image avatar src={event.main_photo.normal} />
            <List.Content>
              <List.Header>{event.name}</List.Header>
            </List.Content>
          </List.Item>
        ))
      ) : (
        <EventsPlaceholder />
      )}
    </List>
  );
};

export default EventsList;
