import React, { useState, useEffect, memo } from 'react';
import RichTextEditor from 'react-rte';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const TextEditor = ({ label, value, onChange }) => {
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const toolbar = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };
  const rootStyle = {
    fontFamily: 'sans-serif',
    marginBottom: '20px'
  };

  const editorStyle = {
    minHeight: '300px',
    fontSize: '14px'
  };

  useEffect(() => {
    setContent(
      RichTextEditor.createValueFromString(
        _.isUndefined(value) ? '' : value,
        'html'
      )
    );
  }, [value]);

  return (
    <div>
      <Form.Field>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
        {!_.isUndefined(label) && <label>{label}</label>}
        <RichTextEditor
          value={content}
          toolbarConfig={toolbar}
          rootStyle={rootStyle}
          editorStyle={editorStyle}
          onChange={val => {
            setContent(val);
            if (!_.isUndefined(onChange)) {
              onChange(val);
            }
          }}
        />
      </Form.Field>
    </div>
  );
};

TextEditor.defaultProps = {
  label: undefined,
  value: '',
  onChange: undefined
};

TextEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default memo(TextEditor);
