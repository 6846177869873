import React from 'react';
import _ from 'underscore';
import { Placeholder } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SchedulesPlaceholder = ({ num }) =>
  _.times(num, i => (
    <Placeholder key={i}>
      <Placeholder.Header>
        <Placeholder.Line length="short" />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  ));

SchedulesPlaceholder.defaultProps = {
  num: 7
};

SchedulesPlaceholder.propTypes = {
  num: PropTypes.number
};

export default SchedulesPlaceholder;
