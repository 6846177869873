import moment from 'moment';
import 'moment/locale/ru';
import _ from 'underscore';

interface ApiSchedule {
  id: number;
  datetime_from: string;
}

interface ApiResponse {
  schedules: [ApiSchedule];
}

interface ScheduleHour {
  id: number;
  hour: string;
}

interface DaySchedule {
  sorter: string;
  date: string;
  hours: [] | [ScheduleHour];
}

interface ScheduleData extends Array<ScheduleHour> {
  [index: number]: ScheduleHour;
}

interface Schedules {
  [key: string]: ScheduleData;
}

type toStringType = (converter: string) => string;

interface DataToApi {
  description: string;
  descriptionRTE: string | { toString: toStringType };
  schedules: [
    {
      hours: [ScheduleHour];
      date: string;
    }
  ];

  [key: string]: any;
}

interface ApiPayload {
  description: string;
  descriptionRTE: null;
  schedules: string;

  [key: string]: any;
}

interface GenericObject {
  [key: string]: any;
}

const fillSchedules = (fromDate: string) =>
  _.times(
    14,
    (i): DaySchedule => {
      const date = moment(
        _.isUndefined(fromDate)
          ? moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')
          : fromDate,
        'YYYY-MM-DD'
      ).add(i + 1, 'days');

      return {
        date: date.format('YYYY-MM-DD'),
        hours: [],
        sorter: date.format('X')
      };
    }
  );

export const fromApi = (jsonData: ApiResponse) => {
  let schedules: any = [];
  const transformedSchedules: Schedules = {};

  jsonData.schedules.forEach((schedule): void => {
    const scheduleHour: ScheduleHour = {
      id: schedule.id,
      hour: moment(schedule.datetime_from, 'YYYY-MM-DDTH:mm').format('H:mm')
    };
    const scheduleKey: string = moment(
      schedule.datetime_from,
      'YYYY-MM-DDTH:mm'
    ).format('YYYY-MM-DD');
    let scheduleData: ScheduleData = transformedSchedules[scheduleKey];
    if (_.isUndefined(scheduleData)) {
      scheduleData = [scheduleHour];
    } else {
      scheduleData.push(scheduleHour);
    }
    transformedSchedules[scheduleKey] = scheduleData;
  });

  Object.keys(transformedSchedules).forEach((key): void => {
    schedules.push({
      sorter: moment(key).format('X'),
      date: key,
      hours: transformedSchedules[key]
    });
  });

  schedules = _.sortBy(schedules, 'sorter');

  const lastScheduleDay: any = _.last(schedules);

  const extraSchedules = fillSchedules(
    _.isUndefined(lastScheduleDay) ? undefined : lastScheduleDay.date
  );

  schedules = schedules.concat(extraSchedules);

  return {
    ...jsonData,
    schedules
  };
};

export const toApi = (jsonData: DataToApi): ApiPayload => {
  const description = _.isUndefined(jsonData.descriptionRTE)
    ? jsonData.description
    : jsonData.descriptionRTE.toString('html');

  const cleanData: GenericObject = {};
  _.each(_.keys(jsonData), (key): void => {
    const value = jsonData[key];
    if (!_.isUndefined(value) && !_.isNull(value)) {
      cleanData[key] = value;
    }
  });

  const dataSchedules = _.filter(
    jsonData.schedules,
    (schedule): boolean => schedule.hours.length > 0
  );

  const schedules: ApiSchedule[] = [];

  _.each(dataSchedules, (schedule): void => {
    _.each(schedule.hours, (hour): void => {
      schedules.push({
        id: hour.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        datetime_from: `${schedule.date}T${hour.hour}`
      });
    });
  });

  return {
    ...cleanData,
    description,
    descriptionRTE: null,
    schedules: JSON.stringify(schedules)
  };
};
