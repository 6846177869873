import React from 'react';
import PropTypes from 'prop-types';

const NotFound = ({ children }) => <div>{children}</div>;

NotFound.defaultProps = {
  children: '404'
};

NotFound.propTypes = {
  children: PropTypes.string
};

export default NotFound;
