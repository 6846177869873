import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import EventForm from './components/EventForm';
import EventsList from './components/EventsList';
import NotFound from './components/NotFound';

const Afisha = () => (
  <Router>
    <Menu pointing secondary>
      <Menu.Item name="События" as={Link} to="/" />
      <Menu.Item name="Добавить" as={Link} to="/events/add/" />
    </Menu>

    <Switch>
      <Route path="/" exact component={EventsList} />
      <Route path="/events/" exact component={EventsList} />
      <Route
        path="/events/add/"
        exact
        component={(props: any) => <EventForm {...props} />}
      />
      <Route path="/events/:id(\d+)" exact component={EventForm} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Afisha;
