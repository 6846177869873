import React, { memo, useState, useEffect } from 'react';
import { Checkbox, Form, Grid, Placeholder } from 'semantic-ui-react';
import _ from 'underscore';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import EventAPI from '../api/Afisha';

const GenresWrapper = styled.div`
  width: 400px;
  column-count: 2;
`;

const GenresPlaceholder = () => (
  <Grid columns={2}>
    <Grid.Column>
      <Placeholder>
        {_.times(10, i => (
          <Placeholder.Line key={i} />
        ))}
      </Placeholder>
    </Grid.Column>
    <Grid.Column>
      <Placeholder>
        {_.times(10, i => (
          <Placeholder.Line key={i} />
        ))}
      </Placeholder>
    </Grid.Column>
  </Grid>
);

const Genres = ({ selected = [], onClick, label = 'Жанр' }) => {
  const [state, setState] = useState({ loaded: false, genres: [] });

  useEffect(() => {
    EventAPI.get('/genres/').then(res => {
      setState({
        loaded: true,
        genres: res.data.results
      });
    });
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
      <Form.Field>
        <label>{label}</label>
      </Form.Field>
      {state.loaded ? (
        <GenresWrapper>
          {state.genres.map(genre => (
            <Checkbox
              className="genre-checkbox"
              label={genre.name}
              value={genre.id}
              key={genre.id}
              defaultChecked={_.contains(selected, genre.id)}
              onClick={onClick}
            />
          ))}
        </GenresWrapper>
      ) : (
        <GenresPlaceholder />
      )}
    </>
  );
};

Genres.defaultProps = {
  selected: [],
  label: 'Жанр'
};

Genres.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string
};

// memo used to prevent re rendering on every state change
export default memo(Genres);
