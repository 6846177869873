import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment';
import DaySchedule from './DaySchedule';

const Schedules = ({ schedules, onHourClick, onMinuteClick }) => (
  <>
    {schedules && schedules.length > 0
      ? schedules.map(schedule => (
          <DaySchedule
            key={schedule.date}
            date={schedule.date}
            hours={schedule.hours}
            handleHourClick={onHourClick}
            handleMinuteClick={onMinuteClick}
          />
        ))
      : _.times(7, i => {
          const date = moment()
            .add(i, 'days')
            .format('YYYY-MM-DD');
          return (
            <DaySchedule
              key={date}
              date={date}
              handleHourClick={onHourClick}
              handleMinuteClick={onMinuteClick}
            />
          );
        })}
  </>
);

Schedules.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHourClick: PropTypes.func.isRequired,
  onMinuteClick: PropTypes.func.isRequired
};

// memo used to prevent re rendering on every state change
export default memo(Schedules);
