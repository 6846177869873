import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import 'moment/locale/ru';
import _ from 'underscore';
import PropTypes from 'prop-types';
import MinutesPopup from './MinutesPopup';

const DatetimeTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 30px;
`;

const DayWrapper = styled.div`
  background-color: white;
  min-width: 350px;
  max-width: 450px;
  margin-bottom: 20px;
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`;

const Hour = styled.div`
  color: #222;
  border: 1px solid ${props => (props.selected ? '#2CB572' : '#D1A682')};
  font-size: 14px;
  width: 46px;
  height: 22px;
  text-align: center;
  background-color: ${props => (props.selected ? '#A8DD9D' : '#EDEBC1')};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  line-height: 20px;
  :hover {
    background-color: ${props => (props.selected ? '#84C277' : '#EFC984')};
  }
`;

class DaySchedule extends React.PureComponent {
  generateMinutePopup = (
    selected = { id: null, hour: null, minute: '00' },
    handleClick = () => {}
  ) => {
    return <MinutesPopup selected={selected} onClick={handleClick} />;
  };

  generateHourList = (
    date,
    from = 0,
    to = 23,
    hours = [],
    handleHourClick = () => {},
    handleMinuteClick = () => {}
  ) => {
    const hoursList = [];
    const transformedSelectedHours = {};
    hours.forEach(v => {
      transformedSelectedHours[_.first(v.hour.split(':'))] = {
        id: v.id,
        hour: _.first(v.hour.split(':')),
        minute: _.last(v.hour.split(':')),
        date
      };
    });

    for (let hour = from; hour <= to; hour += 1) {
      const selectedHour = _.has(transformedSelectedHours, hour.toString());
      const hourData = selectedHour
        ? transformedSelectedHours[hour]
        : { id: null, hour: hour.toString(), minute: '00', date };
      hoursList.push(
        <Hour
          onClick={e => handleHourClick(e, hourData)}
          selected={selectedHour}
          key={hour}
        >
          {hour}:{this.generateMinutePopup(hourData, handleMinuteClick)}
        </Hour>
      );
    }
    return hoursList;
  };

  render() {
    const {
      date,
      from,
      to,
      hours,
      handleHourClick,
      handleMinuteClick
    } = this.props;
    return (
      <DayWrapper>
        <DatetimeTitle>{moment(date).format('D.MM.YYYY, dddd')}</DatetimeTitle>
        <HoursWrapper>
          {this.generateHourList(
            date,
            from,
            to,
            hours,
            handleHourClick,
            handleMinuteClick
          )}
        </HoursWrapper>
      </DayWrapper>
    );
  }
}

DaySchedule.defaultProps = {
  from: 0,
  to: 23,
  hours: [],
  handleHourClick: () => {},
  handleMinuteClick: () => {}
};

DaySchedule.propTypes = {
  date: PropTypes.string.isRequired,
  from: PropTypes.number,
  to: PropTypes.number,
  hours: PropTypes.arrayOf(PropTypes.object),
  handleHourClick: PropTypes.func,
  handleMinuteClick: PropTypes.func
};

export default DaySchedule;
