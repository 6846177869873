import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const MinutesHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  max-width: 175px;
`;

const MinuteBox = styled.div`
  background-color: ${props => (props.selected ? '#A8DD9D' : '#E7E7E7')};
  color: #4a4a4a;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  text-align: center;
  margin: 2px;
  line-height: 25px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.selected ? '#84C277' : '#c7c7c7')};
  }
`;

const Minute = styled.span`
  color: #666;
  text-decoration: underline;
`;

class MinutesPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  generateMinutes = (
    selectedMinute = { id: null, minute: '00' },
    handleClick = () => {}
  ) => {
    const minutes = [];
    for (let minute = 0; minute < 60; minute += 5) {
      const paddedMinute = minute.toString().padStart(2, '0');
      minutes.push(
        <MinuteBox
          selected={paddedMinute === selectedMinute.minute}
          key={minute}
          onClick={e => {
            this.handleClose();
            handleClick(e, { ...selectedMinute, minute: paddedMinute });
          }}
        >
          {paddedMinute}
        </MinuteBox>
      );
    }
    return <MinutesHolder>{minutes}</MinutesHolder>;
  };

  render() {
    const { isOpen } = this.state;
    const { selected, onClick } = this.props;
    return (
      <Popup
        on="click"
        pinned
        open={isOpen}
        onOpen={this.handleOpen}
        trigger={<Minute>{selected.minute}</Minute>}
        content={this.generateMinutes(selected, onClick)}
      />
    );
  }
}

MinutesPopup.defaultProps = {
  selected: { id: null, minute: '00' }
};

MinutesPopup.propTypes = {
  selected: PropTypes.shape({
    id: PropTypes.number,
    minute: PropTypes.string
  }),
  onClick: PropTypes.func.isRequired
};

export default MinutesPopup;
