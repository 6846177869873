import React from 'react';
import _ from 'underscore';
import styled from 'styled-components/macro';
import { Grid, Form, Card, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Genres from './Genres';
import { fromApi, toApi } from '../adapters/Event';
import Schedules from './Schedules';
import EventAPI from '../api/Afisha';
import TextEditor from './TextEditor';
import DataFormSelect from './DataFormSelect';
import SchedulesPlaceholder from './SchedulesPlaceholder';
import EventModal from './EventModal';

const ColumnTitle = styled.h1`
  font-size: 52px;
  color: #444;
  font-weight: bold;
`;

const INITIAL_STATE = {
  loaded: false,
  submitting: false,
  done: false,
  url: null,
  data: {
    genres: [],
    schedules: []
  }
};

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  async componentDidMount() {
    const { match } = this.props;
    if (!_.isUndefined(match.params.id)) {
      const response = await EventAPI.get(`/events/${match.params.id}/`);
      const {
        id,
        name,
        description,
        price,
        category,
        place,
        genres,
        schedules
      } = fromApi(response.data);
      const is3d = response.data.is_3d;
      const photo = response.data.main_photo.original;

      this.setState(state => ({
        ...state,
        loaded: true,
        data: {
          ...state.data,
          description: description.replace(/(\r\n|\n)/g, '<br/>'),
          genres: _.pluck(genres, 'id'),
          schedules,
          id,
          name,
          is3d,
          price,
          category,
          place,
          photo
        }
      }));
    } else {
      this.setState({
        ...INITIAL_STATE,
        loaded: true,
        data: fromApi(INITIAL_STATE.data)
      });
    }
  }

  /*
  selected = {id: null, hour: "3", minute: "00", date: "2019-06-22"}
   */
  handleHourClick = (e, selected) => {
    const selectedHour = `${selected.hour}:${selected.minute}`;
    const { date } = selected;
    this.setState(state => {
      let hourRemoved = false;
      let schedules = _.filter(
        state.data.schedules,
        schedule => schedule.date !== date
      );
      const selectedSchedule = _.findWhere(state.data.schedules, { date });

      // First let's remove hour if it exists
      let hours = [];
      if (selectedSchedule) {
        hours = _.filter(selectedSchedule.hours, hour => {
          const hourValue = _.first(hour.hour.split(':'));
          if (hourValue === selected.hour && hour.hour === selectedHour) {
            hourRemoved = true;
          }
          return hourValue !== selected.hour;
        });
      }

      // Now lets add
      if (!hourRemoved) {
        hours = hours.concat([{ id: null, hour: selectedHour }]);
      }

      schedules = schedules.concat([{ ...selectedSchedule, hours }]);

      // Sort as it won't be done on setting state
      schedules = _.sortBy(schedules, 'sorter');

      return {
        ...state,
        data: {
          ...state.data,
          schedules
        }
      };
    });
  };

  handleMinuteClick = (e, selected) => {
    e.stopPropagation();
    this.handleHourClick(e, selected);
  };

  handleGenreClick = (e, { checked, value }) => {
    this.setState(state => {
      const genres = checked
        ? state.data.genres.concat([value])
        : _.filter(state.data.genres, selectedGenre => selectedGenre !== value);
      return { ...state, data: { ...state.data, genres } };
    });
  };

  handleSubmit = () => {
    const { data } = this.state;
    const formData = new FormData();
    const toApiData = toApi(data);
    this.setState(state => ({
      ...state,
      submitting: true
    }));

    _.each(_.keys(toApiData), key => formData.set(key, toApiData[key]));

    EventAPI.post('/edit/', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    }).then(res => {
      const { url } = res.data;
      this.setState(state => ({
        ...state,
        submitting: false,
        done: true,
        url
      }));
    });
  };

  handleFileChange = e => {
    const photo = _.first(e.target.files);
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        photo: URL.createObjectURL(photo),
        file: photo
      }
    }));
  };

  handlePlaceChange = (e, { value }) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        place: value
      }
    }));
  };

  handleCategoryChange = (e, { value }) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        category: value
      }
    }));
  };

  handleNameChange = (e, { value }) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        name: value
      }
    }));
  };

  handleDescriptionChange = value => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        descriptionRTE: value
      }
    }));
  };

  handlePriceChange = (e, { value }) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        price: value
      }
    }));
  };

  handle3DChange = (e, { checked }) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        is3d: checked
      }
    }));
  };

  handleModalClose = () => {
    this.setState(state => ({
      ...state,
      done: false
    }));
  };

  render() {
    const { data, loaded, submitting, done, url } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} loading={!loaded || submitting}>
        <Grid
          stackable
          columns={3}
          padded="horizontally"
          className="event-grid"
        >
          <Grid.Column className="event-column">
            <ColumnTitle>Событие</ColumnTitle>

            {done && (
              <EventModal
                open={done}
                header={data.name}
                text={data.description}
                imageUrl={data.photo}
                url={url}
                onCloseClick={this.handleModalClose}
              />
            )}

            <DataFormSelect
              label="Категория"
              placeholder="Категория"
              url="/categories/"
              value={data.category || null}
              onChange={this.handleCategoryChange}
            />

            <DataFormSelect
              label="Место проведения"
              placeholder="Место проведения"
              url="/places/"
              value={data.place || null}
              onChange={this.handlePlaceChange}
            />

            <Form.Input
              label="Название"
              fluid
              placeholder="Название"
              value={data.name || ''}
              onChange={this.handleNameChange}
            />

            <Form.Input
              label="Фото"
              type="file"
              onChange={this.handleFileChange}
            />

            {data.photo && (
              <Card fluid>
                <Image rounded wrapped src={data.photo} />
              </Card>
            )}

            <Form.Button positive loading={submitting} disabled={submitting}>
              Сохранить
            </Form.Button>
          </Grid.Column>

          <Grid.Column className="event-column">
            <ColumnTitle>Детали</ColumnTitle>

            <TextEditor
              label="Описание события"
              value={data.description || ''}
              onChange={this.handleDescriptionChange}
            />

            <Form.Input
              label="Цена"
              fluid
              placeholder="Цена"
              value={data.price || ''}
              onChange={this.handlePriceChange}
            />

            <Form.Checkbox
              checked={data.is3d || false}
              label="Показ в 3D"
              onChange={this.handle3DChange}
            />

            <Genres
              selected={data.genres}
              onClick={this.handleGenreClick}
              label="Жанр"
            />

            <p>
              <Form.Button positive loading={submitting} disabled={submitting}>
                Сохранить
              </Form.Button>
            </p>
          </Grid.Column>

          <Grid.Column className="event-column">
            <ColumnTitle>Расписание</ColumnTitle>
            {loaded ? (
              <Schedules
                schedules={data.schedules}
                onHourClick={this.handleHourClick}
                onMinuteClick={this.handleMinuteClick}
              />
            ) : (
              <SchedulesPlaceholder />
            )}

            <Form.Button positive loading={submitting} disabled={submitting}>
              Сохранить
            </Form.Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

EventForm.defaultProps = {
  match: undefined
};

EventForm.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number
  })
};

export default EventForm;
